"use client";

import Script from "next/script";
import {createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {usePathname} from "next/navigation";
import {pixelPageview} from "@/components/analytics/fbpixel";
import {clarity} from 'react-microsoft-clarity';
import {GoogleAnalytics} from "@next/third-parties/google";
import {SpeedInsights} from "@vercel/speed-insights/next";
import {UiAnalyticsTrackEvent} from "@/components/analytics/interfaces/UserAnalyticsService";
import {useUserContext} from "@/lib/hooks/use-user-context";
import {getAnalyticsService} from "@/lib/client-ioc-container";
import {Analytics} from "@vercel/analytics/react"
import {HighlightInit, H} from '@highlight-run/next/client';
import { PostHogProvider } from "@/components/analytics/posthog-provider";
import * as amplitude from '@amplitude/analytics-browser';

export const FbPixelId = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;
export const ClarityId = process.env.NEXT_PUBLIC_CLARITY_ID;
export const GoogleAnalyticsId = process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID;

interface UiAnalyticsProps {
    trackEvent: UiAnalyticsTrackEvent;
    fbTrackEvent: UiAnalyticsTrackEvent;
}

const UiAnalyticsContext = createContext<UiAnalyticsProps | undefined>(undefined);


function UiAnalyticsProvider({children, enabled}: { children: ReactNode, enabled?: boolean }) {

    const [fbPixelLoaded, setFbPixelLoaded] = useState(false);
    const pathname = usePathname();
    const [isAnalyticsSetup, setIsAnalyticsSetup] = useState(false);
    const {user} = useUserContext();
    const userAnalyticsService = useMemo(() => getAnalyticsService(), []);
    const trackEvent = useCallback((eventName: string, eventProperties?: any) => {
        userAnalyticsService.trackEvent(eventName, eventProperties);
    }, [userAnalyticsService]);
    const fbTrackEvent = useCallback((eventName: string, eventProperties?: any) => {
        if (fbPixelLoaded && window.fbq) {
            window.fbq("track", eventName, eventProperties);
        }
    }, [fbPixelLoaded]);

    useEffect(() => {
        amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY!, {"autocapture":true});
    }, []);

    useEffect(() => {
        if (!fbPixelLoaded) return;
        if (ClarityId) {
            clarity.init(ClarityId);
        }
        setIsAnalyticsSetup(true);
        pixelPageview();
    }, [pathname, fbPixelLoaded]);

    useEffect(() => {
        if (!isAnalyticsSetup) return;
        if (!user) return;
        const id = user.email ?? user.uid;
        if (clarity.hasStarted()) {
            clarity.identify(id, user);
        }
        H.identify(id, {
            email: user.email ?? "unknown",
            avatar: user.avatar ?? "unknown",
            name: user.name ?? "unknown",
        });
    }, [isAnalyticsSetup, user]);

    return (
        <UiAnalyticsContext.Provider value={{
            trackEvent: trackEvent,
            fbTrackEvent: fbTrackEvent,
        }}>
            <PostHogProvider>
            {/*<Script src={"/app/clarity-setup.js"} strategy="afterInteractive"/>*/}
            {
                enabled && <>
                    <Analytics/>
                    <SpeedInsights/>
                    <HighlightInit
                        projectId={'mem39zxd'}
                        serviceName="Clicks.video.video"
                        tracingOrigins
                        networkRecording={{
                            enabled: true,
                            recordHeadersAndBody: true,
                            urlBlocklist: [],
                        }}
                    />
                    {
                        GoogleAnalyticsId && <GoogleAnalytics gaId={GoogleAnalyticsId}/>
                    }
                    {
                        FbPixelId && <Script
                            id="fb-pixel"
                            src="/app/scripts/pixel.js"
                            strategy="lazyOnload"
                            onLoad={(e) => {
                                setFbPixelLoaded(true);
                                console.log(e);
                            }}
                            data-pixel-id={FbPixelId}
                        />
                    }
                </>
            }
            {/*<noscript>*/}
            {/*    <img*/}
            {/*        height="1"*/}
            {/*        width="1"*/}
            {/*        style={{display: "none"}}*/}
            {/*        src={`https://www.facebook.com/tr?id=${FB_PIXEL_ID}&ev=PageView&noscript=1`}*/}
            {/*    />*/}
            {/*</noscript>*/}
            {children}
            </PostHogProvider>
        </UiAnalyticsContext.Provider>
    );
}

const useUiAnalyticsContext = () => {
    const context = useContext(UiAnalyticsContext);
    if (!context) {
        throw new Error("useUiAnalyticsContext must be used within a UiAnalyticsProvider");
    }
    return context;
}

export {UiAnalyticsProvider, useUiAnalyticsContext};
