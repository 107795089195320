"use client";

import {
    GoogleAuthProvider,
    signInWithPopup,
    onAuthStateChanged as _onAuthStateChanged,
    User,
    UserCredential,
    getAdditionalUserInfo,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword, sendPasswordResetEmail,
} from "firebase/auth";

import {auth} from "@/lib/firebase/clientApp";
import {clearIdTokenCookie, setIdTokenCookie} from "@/lib/ui-utils";
import {FirebaseError} from "firebase/app";

type AuthStateChangeCallback = (user: User | null) => Promise<void>;

export function onAuthStateChanged(cb: AuthStateChangeCallback) {
    return _onAuthStateChanged(auth, cb);
}

type SignInCallback = (userCredential: UserCredential) => Promise<void>;
type EmailAlreadyExistCallback = (email: string) => Promise<void>;
type SignInFailedCallback = (error: Error) => Promise<void>;

export async function signInWithGoogle(signInCallback?: SignInCallback, signInFailedCallback?: SignInFailedCallback) {
    const provider = new GoogleAuthProvider();

    try {
        const creds = await signInWithPopup(auth, provider);
        setIdTokenCookie(await creds.user.getIdToken(true));
        signInCallback && await signInCallback(creds);
    } catch (error) {
        console.error("Error signing in with Google", error);
        signInFailedCallback && await signInFailedCallback(new Error("Error signing in with Google. Please try again."));
    }
}

export async function signInWithEmail(email: string, password: string, signInCallback?: SignInCallback, emailAlreadyExistCallback?: EmailAlreadyExistCallback, signInFailedCallback?: SignInFailedCallback) {
    try {
        const creds = await signInWithEmailAndPassword(auth, email, password);
        setIdTokenCookie(await creds.user.getIdToken(true));
        signInCallback && await signInCallback(creds);
    } catch (error) {
        if (error instanceof FirebaseError) {
            if (error.code === "auth/invalid-credential") { // User does not exist
                await createUserWithEmail(email, password, signInCallback, emailAlreadyExistCallback);
                return;
            } else {
                console.error("Error signing in with email", error);
            }
        } else {
            console.error("Error signing in with email", error);
        }
        signInFailedCallback && await signInFailedCallback(new Error("Error signing in with email. Please try again."));
    }
}

export async function createUserWithEmail(email: string, password: string, signInCallback?: SignInCallback, emailAlreadyExistCallback?: EmailAlreadyExistCallback, signInFailedCallback?: SignInFailedCallback) {
    try {
        const creds = await createUserWithEmailAndPassword(auth, email, password);
        setIdTokenCookie(await creds.user.getIdToken(true));
        signInCallback && await signInCallback(creds);
    } catch (error) {
        if (error instanceof FirebaseError) {
            if (error.code === "auth/email-already-in-use") {
                console.log("Email already in use");
                emailAlreadyExistCallback && await emailAlreadyExistCallback(email);
                return;
            } else {
                console.error("Error creating user with email", error);
            }
        } else {
            console.error("Error signing in with email", error);
        }
        signInFailedCallback && await signInFailedCallback(new Error("Error creating user with email. Please try again."));
    }
}


export async function resetPassword(email: string) {
    try {
        await sendPasswordResetEmail(auth, email)
    } catch (error) {
        console.error("Error resetting password", error);
    }
}

export async function signOut() {
    try {
        clearIdTokenCookie();
        await auth.signOut();
    } catch (error) {
        console.error("Error signing out with Google", error);
    }
}
