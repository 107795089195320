export const pixelPageview = () => {
    try {
        window?.fbq("track", "PageView");
    } catch (e) {
        console.error(e);
    }
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name, options = {}) => {
    try {
        window?.fbq("track", name, options);
    } catch (e) {
        console.error(e);
    }
};
