const epochNow = () => {
    return Math.floor(Date.now() / 1000);
};

const epochToday = () => {
    return Math.floor(epochNow() / 86400) * 86400;
}

export const formatDateStringYYYYMMDD = (date: Date): string => {
    const formattedDate = [
        date.getFullYear(),
        (date.getMonth() + 1).toString().padStart(2, "0"),
        date.getDate().toString().padStart(2, "0"),
    ].join("");

    return formattedDate;
};

export {epochNow, epochToday};
