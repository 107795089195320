"use client";

import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from "react";

import {AppUser} from "@/models/AppUser";
import {clearIdTokenCookie, setIdTokenCookie} from "@/lib/ui-utils";
import {onAuthStateChanged} from "@/lib/firebase/auth";
import {User} from "firebase/auth";
import {auth} from "@/lib/firebase/clientApp";
import {jwtDecode} from "jwt-decode";
import {epochNow} from "@/lib/date-utils";
import {useRouter} from "next/navigation";
import {getUserSocialConnectionStatus, SocialConnectionStatus} from "@/app/social-actions";
import {initializePostHogUser} from "../../components/analytics/impls/PosthogUserAnalyticsService";
import {initializeAmplitudeUser} from "../../components/analytics/impls/AmplitudeUserAnalyticsService";

interface UserContextProps {
    user: (AppUser & SocialConnectionStatus) | null;
    tyrTokenRefresh: () => Promise<boolean>;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

const UserContextProvider = ({children}: { children: ReactNode }) => {
    const [user, setUser] = useState<(AppUser & SocialConnectionStatus) | null>(
        null,
    );
    const router = useRouter();

    const setupUser = useCallback(async (authUser: User) => {
        const user = authUser
        console.log("authUser", authUser);

        let userSocialConnectionStatus: SocialConnectionStatus = {
            hasTikTok: false,
            hasInstagram: false,
        }
        try {
            userSocialConnectionStatus = await getUserSocialConnectionStatus();
            console.debug("userSocialConnectionStatus", userSocialConnectionStatus);
        } catch (err) {
            console.error(err);
        }

        if (user) {
            const {displayName, email, photoURL, uid} = user;
            const idToken = await user.getIdToken(true);
            setUser({
                uid: uid,
                name: displayName,
                email: email,
                avatar: photoURL,
                accessToken: idToken,
                ...userSocialConnectionStatus,
            });
            setIdTokenCookie(idToken);
            console.log("setting new token: ", idToken);
            initializePostHogUser(user);
            initializeAmplitudeUser(user);
        } else {
            setUser(null);
            clearIdTokenCookie();
        }
    }, []);

    const refreshTokenIfExpired = useCallback(async (user: User) => {
        const currentToken = await user.getIdToken();
        console.debug("current token:", currentToken);

        const {exp} = jwtDecode(currentToken);
        if (!exp) {
            console.error("JWT does not have exp. It might be bad token");
            return;
        }
        if (exp + 1000 < epochNow()) {
            console.debug("Force refreshing token")
            await user.getIdToken(true);
        }

        await setupUser(auth.currentUser!);
    }, [setupUser]);


    useEffect(() => {
        const timeoutRef: {
            timeout?: NodeJS.Timeout
        } = {
            timeout: undefined
        }

        const unsubscribeAuthChangeHandler = onAuthStateChanged(async (authUser: User | null) => {
            const user = authUser
            if (user) {
                console.log("setting user", user);
                await setupUser(user);

                user.getIdToken().then((token) => {
                    console.debug("current token:", token);
                    const {exp} = jwtDecode(token);
                    if (!exp) {
                        return;
                    }
                    const timeDiffSeconds = 600; // refresh every 10 minutes
                    console.log(`Refresh auth in ${timeDiffSeconds} seconds`);
                    timeoutRef.timeout = setInterval(async () => {
                        console.log("Attempting to refresh auth");
                        if (auth.currentUser) {
                            await refreshTokenIfExpired(auth.currentUser);
                        } else {
                            router.push("/");
                        }
                    }, timeDiffSeconds * 1000);
                });
            } else {
                console.warn("Unable to verify user auth.");
            }
        });


        if (auth.currentUser) {
            setupUser(auth.currentUser).then((user) => {
                console.log("current user", user);
            });
        }

        return () => {
            unsubscribeAuthChangeHandler();
            if (timeoutRef.timeout) {
                clearTimeout(timeoutRef.timeout)
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshTokenIfExpired]);

    useEffect(() => {
        const visibilityChangeEventName = "visibilitychange";
        const visibilityListener = () => {
            if (document.visibilityState === "visible") {
                console.log("Proactively refresh auth");
                auth.currentUser?.getIdToken(true).then((idToken) => {
                    console.log("Refreshed token on tab activation");
                });
            }
        };
        document.addEventListener(visibilityChangeEventName, visibilityListener);

        const unsubscribeVisibilityListener = () => {
            document.removeEventListener(visibilityChangeEventName, visibilityListener);
        };

        return () => {
            unsubscribeVisibilityListener();
        }
    }, []);

    const tokenRefreshCallback = useCallback(async () => {
        const idToken = await auth.currentUser?.getIdToken(true);
        if (idToken) {
            setIdTokenCookie(idToken);
            return true;
        }
        return false;
    }, []);

    return (
        <UserContext.Provider value={{
            user,
            tyrTokenRefresh: tokenRefreshCallback,
        }}>
            {children}
        </UserContext.Provider>
    );
}

const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useUserContext must be used within a UserContextProvider");
    }
    return context;
}

export {UserContextProvider, useUserContext};

// export function useUserContext() {
//     const [user, setUser] = useState<null | PeepsUser>(null);
//
//     useEffect(() => {
//         const unsubscribe = onAuthStateChanged(async (authUser: User) => {
//             const user = authUser
//             console.log("authUser", authUser);
//             if (user) {
//                 const {displayName, email, photoURL, uid} = user;
//                 const idToken = await user.getIdToken(true);
//                 setUser({
//                     id: uid,
//                     name: displayName,
//                     email: email,
//                     avatar: photoURL,
//                     accessToken: idToken,
//                 });
//                 // document.cookie += `idToken=${idToken}; path=/`;
//                 setIdTokenCookie(idToken);
//             } else {
//                 setUser(null);
//                 clearIdTokenCookie();
//             }
//         });
//
//         return () => unsubscribe();
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, []);
//
//     return user;
// }
